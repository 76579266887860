import React from "react"
import {  graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideBar from "../components/sideBar"
import RelatedLinks from "../components/relatedLinks"
import Image from "../components/image"

import { layoutStyles } from '../style/layoutStyles';
import SectionLinks from "../components/sectionLinks";

export default ({ data }) => {
  const post = data.wpgraphql.port
  var relatedLinks = [];
  var relatedPages=[];
  var relatedDocuments=[];
  var featuredImage =[];
  var showsidebar = "true";
  var keywords = [];
  if (post.keywords.nodes != null)
  {
    post.keywords.nodes.forEach(node => { keywords.push(node.name);});
  }
  if (post.featuredImage != null)
  {
    featuredImage.push({"url":post.featuredImage.node.mediaItemUrl,"title":post.featuredImage.node.altText, "link": post.AllPostTypeFields.bannerlink});
  }
  if (post.AllPostTypeFields.relatedDocuments.relatedDocument1 != null)
  {
    if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle1 != null)
    relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle1, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument1.mediaItemUrl})
    else
      relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument1.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument1.mediaItemUrl})
  }
  if (post.AllPostTypeFields.relatedDocuments.relatedDocument2 != null)
  {
    if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle2 != null)
    relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle2, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument2.mediaItemUrl})
    else
    relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument2.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument2.mediaItemUrl})
  }

  if (post.AllPostTypeFields.relatedDocuments.relatedDocument3 != null){
    if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle3 != null)
    relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle3, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument3.mediaItemUrl})
    else
    relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument3.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument3.mediaItemUrl})
  }

if (post.AllPostTypeFields.relatedDocuments.relatedDocument4 != null)
{
  if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle4 != null)
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle4, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument4.mediaItemUrl})
  else
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument4.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument4.mediaItemUrl})
}

if (post.AllPostTypeFields.relatedDocuments.relatedDocument5 != null)
{
  if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle5 != null)
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle5, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument5.mediaItemUrl})
  else
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument5.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument5.mediaItemUrl})
}

if (post.AllPostTypeFields.relatedDocuments.relatedDocument6 != null)
{
  if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle6 != null)
    relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle6, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument6.mediaItemUrl})
  else
    relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument6.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument6.mediaItemUrl})
}

if (post.AllPostTypeFields.relatedDocuments.relatedDocument7 != null)
{
  if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle7 != null)
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle7, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument7.mediaItemUrl})
  else
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument7.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument7.mediaItemUrl})

}

if (post.AllPostTypeFields.relatedDocuments.relatedDocument8 != null)
{
  if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle8 != null)
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle8, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument8.mediaItemUrl})
  else
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument8.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument8.mediaItemUrl})

}

if (post.AllPostTypeFields.relatedDocuments.relatedDocument9 != null)
{
  if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle9 != null)
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle9, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument9.mediaItemUrl})
else
relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument9.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument9.mediaItemUrl})
}

if (post.AllPostTypeFields.relatedDocuments.relatedDocument10 != null)
{
  if(post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle10 != null)
  relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocumentTitle10, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument10.mediaItemUrl})
else
relatedDocuments.push({"title": post.AllPostTypeFields.relatedDocuments.relatedDocument10.title, "url" : post.AllPostTypeFields.relatedDocuments.relatedDocument10.mediaItemUrl})

}
if (post.AllPostTypeFields.relatedLinks.relatedLink1 != null)
relatedLinks.push({ "id": 0, "title": post.AllPostTypeFields.relatedLinks.relatedLink1.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink1.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink1.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink2 != null)
relatedLinks.push({ "id": 1, "title": post.AllPostTypeFields.relatedLinks.relatedLink2.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink2.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink2.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink3 != null)
relatedLinks.push({ "id": 2, "title": post.AllPostTypeFields.relatedLinks.relatedLink3.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink3.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink3.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink4 != null)
relatedLinks.push({ "id": 3, "title": post.AllPostTypeFields.relatedLinks.relatedLink4.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink4.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink4.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink5 != null)
relatedLinks.push({ "id": 4, "title": post.AllPostTypeFields.relatedLinks.relatedLink5.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink5.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink5.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink6 != null)
relatedLinks.push({ "id": 5, "title": post.AllPostTypeFields.relatedLinks.relatedLink6.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink6.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink6.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink7 != null)
relatedLinks.push({ "id": 6, "title": post.AllPostTypeFields.relatedLinks.relatedLink7.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink7.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink7.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink8 != null)
relatedLinks.push({ "id": 7, "title": post.AllPostTypeFields.relatedLinks.relatedLink8.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink8.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink8.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink9 != null)
relatedLinks.push({ "id": 8, "title": post.AllPostTypeFields.relatedLinks.relatedLink9.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink9.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink9.target});

if (post.AllPostTypeFields.relatedLinks.relatedLink10 != null)
relatedLinks.push({ "id": 9, "title": post.AllPostTypeFields.relatedLinks.relatedLink10.title, "url" : post.AllPostTypeFields.relatedLinks.relatedLink10.url, "target": post.AllPostTypeFields.relatedLinks.relatedLink10.target});


if (post.AllPostTypeFields.relatedSectionPages.relatedPage1 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage1 != null)
relatedPages.push({ "id": 0, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage1.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage1.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage1.target,"imagetitle": post.AllPostTypeFields.contentImage.contentImage1.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage1.mediaItemUrl});
else
relatedPages.push({ "id": 0, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage1.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage1.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage1.target,"imagetitle": "", "imageurl" : ""});
}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage2 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage2 != null)
relatedPages.push({ "id": 1, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage2.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage2.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage2.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage2.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage2.mediaItemUrl});
else
relatedPages.push({ "id": 1, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage2.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage2.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage2.target,"imagetitle": "", "imageurl" : ""});
}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage3 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage3 != null)
relatedPages.push({ "id": 2, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage3.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage3.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage3.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage3.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage3.mediaItemUrl});
else
relatedPages.push({ "id": 2, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage3.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage3.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage3.target,"imagetitle": "", "imageurl" : ""});
}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage4 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage4 != null)
relatedPages.push({ "id": 3, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage4.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage4.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage4.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage4.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage4.mediaItemUrl});
else
relatedPages.push({ "id": 3, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage4.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage4.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage4.target,"imagetitle": "", "imageurl" : ""});
}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage5 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage5 != null)
relatedPages.push({ "id": 4, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage5.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage5.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage5.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage5.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage5.mediaItemUrl});
else
relatedPages.push({ "id": 4, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage5.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage5.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage5.target,"imagetitle": "", "imageurl" : ""});

}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage6 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage6 != null)
relatedPages.push({ "id": 5, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage6.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage6.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage6.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage6.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage6.mediaItemUrl});
else
relatedPages.push({ "id": 5, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage6.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage6.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage6.target,"imagetitle": "", "imageurl" : ""});
}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage7 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage7 != null)
relatedPages.push({ "id": 6, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage7.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage7.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage7.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage7.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage7.mediaItemUrl});
else
relatedPages.push({ "id": 6, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage7.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage7.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage7.target,"imagetitle": "", "imageurl" : ""});
}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage8 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage8 != null)
relatedPages.push({ "id": 7, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage8.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage8.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage8.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage8.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage8.mediaItemUrl});
else
relatedPages.push({ "id": 7, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage8.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage8.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage8.target,"imagetitle": "", "imageurl" : ""});
}
if (post.AllPostTypeFields.relatedSectionPages.relatedPage9 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage9 != null)
 relatedPages.push({ "id": 8, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage9.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage9.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage9.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage9.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage9.mediaItemUrl});
else
relatedPages.push({ "id": 8, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage9.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage9.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage9.target,"imagetitle": "", "imageurl" : ""});

}

if (post.AllPostTypeFields.relatedSectionPages.relatedPage10 != null)
{
if (post.AllPostTypeFields.contentImage.contentImage10 != null)
relatedPages.push({ "id": 9, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage10.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage10.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage10.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage10.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage10.mediaItemUrl});
else
relatedPages.push({ "id": 9, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage10.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage10.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage10.target,"imagetitle": "", "imageurl" : ""});

}
if((relatedLinks.length===0)&&(relatedDocuments.length===0))
showsidebar = "false";
  return (
    <Layout>
       <SEO title={post.title} keywords={keywords} />
        <div css={layoutStyles} id="content">
        <div className="grid">
          <div className="content col-1-1">
            <div className="masthead">
                <div className="masthead col-1-1">
                <Image scr={featuredImage} Mode="port"/> 
                </div>
            </div>
          </div>
            <div id="sectionPage" className="col-1-1">
              <div id="article" className={showsidebar==="true"?"col-6-12":"col-9-12"}>
                <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
              <SectionLinks SectionLinks={relatedPages} Mode="port"  />
              </div>
              <div id="aside" className={showsidebar==="true"?"col-6-12":"col-3-12"}>
                {showsidebar==="true"&&<RelatedLinks RelatedLinks={relatedLinks} RelatedDocuments={relatedDocuments} Template="Landing"/>}
                {showsidebar==="true"&&<SideBar />}
                {showsidebar==="false"&&<SideBar FullWidth="true" />}
              </div>
            </div> 
        </div>
        <div className="whitespacer"></div>
      </div>

    </Layout>
  )
}
export const query = graphql`
query GET_PORTLANDING($id: ID!) {
  wpgraphql {
      port(id: $id) {
        title
        content(format: RENDERED)
        slug
        keywords {
          nodes {
            name
          }
        }
        featuredImage {
          node
          {
          mediaItemUrl
          altText
          }
        }
        children {
          nodes {
            uri
          }
        }
        AllPostTypeFields {
          bannerlink
          contentImage{
            contentImage1 {
              mediaItemUrl
              title
            }
            contentImage2 {
              mediaItemUrl
              title
            }
            contentImage3 {
              mediaItemUrl
              title
            }
            contentImage4 {
              mediaItemUrl
              title
            }
            contentImage5 {
              mediaItemUrl
              title
            }
            contentImage6 {
              mediaItemUrl
              title
            }
            contentImage7{
              mediaItemUrl
              title
            }
            contentImage8 {
              mediaItemUrl
              title
            }
            contentImage9 {
              mediaItemUrl
              title
            }
            contentImage10 {
              mediaItemUrl
              title
            }

          }
          relatedLinks {
            relatedLink1 {
              title
              url
              target
            }
            relatedLink2 {
              title
              url
              target
            }
            relatedLink3 {
              title
              url
              target
            }
            relatedLink4 {
              title
              url
              target
            }
            relatedLink5 {
              title
              url
              target
            }
            relatedLink6 {
              title
              url
              target
            }
            relatedLink7 {
              title
              url
              target
            }
            relatedLink8 {
              title
              url
              target
            }
            relatedLink9 {
              title
              url
              target
            }
            relatedLink10 {
              title
              url
              target
            }
          }
          relatedSectionPages {
            relatedPage1 {
            title
            url
            target
          }
          relatedPage2 {
            title
            url
            target
          }
          relatedPage3 {
            title
            url
            target
          }
          relatedPage4 {
            title
            url
            target
          }
          relatedPage5 {
            title
            url
            target
          }
          relatedPage6 {
            title
            url
            target
          }
          relatedPage7 {
            title
            url
            target
          }
          relatedPage8 {
            title
            url
            target
          }
          relatedPage9 {
            title
            url
            target
          }
          relatedPage10 {
            title
            url
            target
          }
        }
        relatedDocuments {
          relatedDocumentTitle1
          relatedDocumentTitle2
          relatedDocumentTitle3
          relatedDocumentTitle4
          relatedDocumentTitle5
          relatedDocumentTitle6
          relatedDocumentTitle7
          relatedDocumentTitle8
          relatedDocumentTitle9
          relatedDocumentTitle10
          relatedDocument1 {
            mediaItemUrl
            title
          }
          relatedDocument2 {
            mediaItemUrl
            title
          }
          relatedDocument3 {
            mediaItemUrl
            title
          }
          relatedDocument4 {
            mediaItemUrl
            title
          }
          relatedDocument5 {
            mediaItemUrl
            title
          }
          relatedDocument6 {
            mediaItemUrl
            title
          }
          relatedDocument7 {
            mediaItemUrl
            title
          }
          relatedDocument8 {
            mediaItemUrl
            title
          }
          relatedDocument9 {
            mediaItemUrl
            title
          }
          relatedDocument10 {
            mediaItemUrl
            title
          }
        }
      }
    }
  }
}
`